import React, { Component } from 'react'
import { Box, Heading } from 'grommet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchBugfixList, toggleBug } from '../../actions/bugfixList'
import { PacmanLoader } from 'react-spinners'
import { qlikBrand } from '../../branding'
import ProjectCard from '../../components/ProjectCard'

const renderBugfixProjects = ({ bugfixProjects, fetchBugfixListInProgress, toggleBug }) => {
  if (fetchBugfixListInProgress) {
    return null
  }
  if (!bugfixProjects.length) {
    return (
      <Box align='center'>No projects!</Box>
    )
  } else {
    return (
      <Box align='center'>
        {bugfixProjects.map((project, index) => (
          <ProjectCard key={project._id} project={project} toggleBug={toggleBug} projectIndex={index} />
        ))}
      </Box>
    )
  }
}

export class BugfixProjectList extends Component {
  async componentDidMount () {
    await this.props.fetchBugfixList()
  }
  render () {
    return (
      <Box align='stretch' fill='horizontal' jusify='center'>
        <Box align='center'><Heading level='2'>Bugfix Projects</Heading></Box>
        <Box align='center'><PacmanLoader
          loading={this.props.fetchBugfixListInProgress}
          color={qlikBrand.core.green}
          sizeUnit='px'
          size={50}
        /></Box>
        {renderBugfixProjects({
          bugfixProjects: this.props.bugfixList,
          fetchBugfixListInProgress: this.props.fetchBugfixListInProgress,
          toggleBug: this.props.toggleBug
        })}
      </Box>
    )
  }
}

BugfixProjectList.propTypes = {
  fetchBugfixList: PropTypes.func.isRequired,
  bugfixList: PropTypes.array.isRequired,
  fetchBugfixListInProgress: PropTypes.bool.isRequired,
  toggleBug: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    bugfixList: state.bugfixList,
    fetchBugfixListHasErrored: state.fetchBugfixListHasErrored,
    fetchBugfixListInProgress: state.fetchBugfixListInProgress
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBugfixList: () => dispatch(fetchBugfixList()),
    toggleBug: (project, bug) => dispatch(toggleBug(project, bug))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BugfixProjectList)
