import React, { Component } from 'react'
import { Box, Text, Meter, Grid } from 'grommet'
import { qlikBrand } from '../../branding'
import BugCard from '../BugCard'

const statusMap = {
  0: {
    value: 'Error',
    color: 'status-error'
  },
  10: {
    value: 'Creating projects in XTM',
    color: qlikBrand.flood.tanzanite
  },
  20: {
    value: 'Analyzing Bugfix projects',
    color: qlikBrand.flood.slate
  },
  30: {
    value: 'Starting projects in XTM',
    color: qlikBrand.flood.turquoise
  },
  40: {
    value: 'XTM Projects In Translation',
    color: qlikBrand.flood.wine
  },
  50: {
    value: 'Generating project files',
    color: qlikBrand.flood.aquamarine
  },
  60: {
    value: 'Pull requests created',
    color: 'status-ok'
  }
}

export class ProjectCard extends Component {
  render () {
    const { project, toggleBug, projectIndex } = this.props
    return (
      <Box background='light-3' pad='large' margin='medium' round>
        <Box>
          <Grid
            rows={['flex', 'flex', 'flex', 'flex']}
            columns={['xsmall', 'flex']}
            gap='xsmall'
            fill
            areas={[
              { name: 'projectLabel', start: [0, 0], end: [0, 0] },
              { name: 'project', start: [1, 0], end: [1, 0] },
              { name: 'statusLabel', start: [0, 1], end: [0, 1] },
              { name: 'status', start: [1, 1], end: [1, 1] },
              { name: 'awsStatusLabel', start: [0, 2], end: [0, 2] },
              { name: 'awsStatus', start: [1, 2], end: [1, 2] },
              { name: 'progressLabel', start: [0, 3], end: [0, 3] },
              { name: 'progress', start: [1, 3], end: [1, 3] }
            ]}
          >
            <Box direction='row' gridArea='projectLabel'>
              <Text weight='bold'>Project</Text>
            </Box>
            <Box direction='row' gridArea='project'>
              <Text>{ project.name }</Text>
            </Box>
            <Box direction='row' gridArea='statusLabel'>
              <Text weight='bold'>Status</Text>
            </Box>
            <Box direction='row' gridArea='status'>
              <Text>{ statusMap[project.statusCode].value }</Text>
            </Box>
            <Box direction='row' gridArea='awsStatusLabel'>
              <Text weight='bold'>AWS Status</Text>
            </Box>
            <Box direction='row' gridArea='awsStatus'>
              {['FAILED', 'TIMED_OUT', 'ABORTED'].includes(project.awsStatus) ? (<Text color='red'>{ project.awsStatus || 'No Status' }</Text>) : (<Text color='green'>{ project.awsStatus || 'No Status' }</Text>)}
            </Box>
            <Box direction='row' gridArea='progressLabel'>
              <Text weight='bold'>Progress</Text>
            </Box>
            <Box direction='row' gridArea='progress'>
              <Meter
                type='bar'
                values={[
                  { value: project.statusCode, color: 'neutral-3' },
                  { value: 60 - project.statusCode, color: 'accent-2' }
                ]}
              />
            </Box>
          </Grid>
        </Box>
        <Box>
          {project.bugs.map((bug, index) => (<BugCard key={bug._id} bug={bug} toggleBug={toggleBug} projectIndex={projectIndex} bugIndex={index} />))}
        </Box>
      </Box>
    )
  }
}

export default ProjectCard
