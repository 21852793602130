import { actionTypes } from '../actions/bugfixProject'

const defaultTerm = {
  _id: '',
  term: '',
  context: '',
  translations: []
}

export function bugfixProject (state = defaultTerm, action) {
  switch (action.type) {
    case actionTypes.ADD_BUGFIX_PROJECT_SUCCESSFUL:
      return action.payload
    default:
      return state
  }
}

export function addBugfixProjectHasErrored (state = false, action) {
  switch (action.type) {
    case actionTypes.ADD_BUGFIX_PROJECT_HAS_ERRORED:
    case actionTypes.ADD_BUGFIX_PROJECT_HAS_ERRORED_RESET:
      return action.addBugfixProjectHasErrored
    default:
      return state
  }
}

export function addBugfixProjectInProgess (state = false, action) {
  switch (action.type) {
    case actionTypes.ADD_BUGFIX_PROJECT_IN_PROGRESS:
    case actionTypes.ADD_BUGFIX_PROJECT_IN_PROGRESS_RESET:
      return action.addBugfixProjectInProgress
    default:
      return state
  }
}
