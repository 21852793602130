import { API } from 'aws-amplify'

export const actionTypes = {
  FETCH_REPOSITORIES: 'FETCH_REPOSITORIES',
  FETCH_REPOSITORIES_HAS_ERRORED: 'FETCH_REPOSITORIES_HAS_ERRORED',
  FETCH_REPOSITORIES_HAS_ERRORED_RESET: 'FETCH_REPOSITORIES_HAS_ERRORED_RESET',
  FETCH_REPOSITORIES_IN_PROGRESS: 'FETCH_REPOSITORIES_IN_PROGRESS',
  FETCH_REPOSITORIES_IN_PROGRESS_RESET: 'FETCH_REPOSITORIES_IN_PROGRESS_RESET',
  FETCH_REPOSITORIES_SUCCESSFUL: 'FETCH_REPOSITORIES_SUCCESSFUL'
}

export function fetchRepositoriesHasErrored (error) {
  console.log(error)
  return (dispatch) => {
    dispatch(fetchRepositoriesInProgressReset())
    dispatch({
      type: actionTypes.FETCH_REPOSITORIES_HAS_ERRORED,
      fetchRepositoriesHasErrored: true
    })
  }
}

export function fetchRepositoriesHasErroredReset () {
  return {
    type: actionTypes.FETCH_REPOSITORIES_HAS_ERRORED_RESET,
    fetchRepositoriesHasErrored: false
  }
}

export function fetchRepositoriesInProgress () {
  return {
    type: actionTypes.FETCH_REPOSITORIES_IN_PROGRESS,
    fetchRepositoriesInProgress: true
  }
}

export function fetchRepositoriesInProgressReset () {
  return {
    type: actionTypes.FETCH_REPOSITORIES_IN_PROGRESS_RESET,
    fetchRepositoriesInProgress: false
  }
}

export function fetchRepositoriesSuccessful (payload) {
  return (dispatch) => {
    dispatch(fetchRepositoriesInProgressReset())
    dispatch({
      type: actionTypes.FETCH_REPOSITORIES_SUCCESSFUL,
      payload
    })
  }
}

export function fetchRepositories () {
  return (dispatch) => {
    dispatch(fetchRepositoriesHasErroredReset())
    dispatch(fetchRepositoriesInProgress())
    return API.get('FetchRepos', 'repositories')
      .then(res => dispatch(fetchRepositoriesSuccessful(res)))
      .catch(error => dispatch(fetchRepositoriesHasErrored(error)))
  }
}
