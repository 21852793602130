export const actionTypes = {
  ADD_SUGGESTIONS: 'ADD_SUGGESTIONS',
  REMOVE_BUG_FIELD: 'REMOVE_BUG_FIELD',
  REMOVE_STRING_FIELD: 'REMOVE_STRING_FIELD',
  ADD_BUG_FIELD: 'ADD_BUG_FIELD',
  ADD_STRING_FIELD: 'ADD_STRING_FIELD'
}

export function addSuggestions (bugIndex, stringIndex, suggestions) {
  return {
    type: actionTypes.ADD_SUGGESTIONS,
    bugIndex,
    stringIndex,
    suggestions
  }
}

export function addBugField () {
  return {
    type: actionTypes.ADD_BUG_FIELD
  }
}

export function addStringField (bugIndex) {
  return {
    type: actionTypes.ADD_STRING_FIELD,
    bugIndex
  }
}

export function removeBugField (bugIndex) {
  return {
    type: actionTypes.REMOVE_BUG_FIELD,
    bugIndex
  }
}

export function removeStringField (bugIndex, stringIndex) {
  return {
    type: actionTypes.REMOVE_STRING_FIELD,
    bugIndex,
    stringIndex
  }
}
