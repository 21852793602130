import { actionTypes } from '../actions/repositories'

export function repositories (state = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSITORIES_SUCCESSFUL:
      return action.payload
    default:
      return state
  }
}

export function repoSuggestions (state = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSITORIES_SUCCESSFUL:
      let repositories = action.payload
      return repositories.map((repo) => {
        return repo.description ? `${repo.name} - ${repo.description}` : repo.name
      })
    default:
      return state
  }
}

export function fetchRepositoriesHasErrored (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSITORIES_HAS_ERRORED:
    case actionTypes.FETCH_REPOSITORIES_HAS_ERRORED_RESET:
      return action.fetchRepositoriesHasErrored
    default:
      return state
  }
}

export function fetchRepositoriesInProgress (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSITORIES_IN_PROGRESS:
    case actionTypes.FETCH_REPOSITORIES_IN_PROGRESS_RESET:
      return action.fetchRepositoriesInProgress
    default:
      return state
  }
}
