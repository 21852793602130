import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import AddBugfixProject from '../AddBugfixProject'
import BugfixProjectList from '../BugfixProjectList'

export class Main extends Component {
  render () {
    return (
      <main>
        <Switch>
          <Route exact path='/' component={BugfixProjectList} />
          <Route exact path='/bugfix/add' component={AddBugfixProject} />
        </Switch>
      </main>
    )
  }
}

export default Main
