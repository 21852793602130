import { actionTypes } from '../actions/repoStringsSuggestions'
import { actionTypes as repoStringActionTypes } from '../actions/repoStrings'

import cloneDeep from 'lodash/cloneDeep'

export function repoStringsSuggestions (state = [], action) {
  switch (action.type) {
    case actionTypes.ADD_SUGGESTIONS:
      let newState = cloneDeep(state)
      newState[action.bugIndex][action.stringIndex] = action.suggestions
      return newState
    case repoStringActionTypes.FETCH_REPOSTRINGS_SUCCESSFUL:
      let newStringSuccessState = cloneDeep(state)
      let suggestions = action.payload.map((suggestion) => { return suggestion.name })
      newStringSuccessState[action.bugIndex][action.stringIndex] = suggestions
      return newStringSuccessState
    case actionTypes.ADD_BUG_FIELD:
      let newBugFieldState = cloneDeep(state)
      newBugFieldState.push([])
      return newBugFieldState
    case actionTypes.ADD_STRING_FIELD:
      let newStringFieldState = cloneDeep(state)
      newStringFieldState[action.bugIndex].push([])
      return newStringFieldState
    case actionTypes.REMOVE_BUG_FIELD:
      let newRemoveBugFieldState = cloneDeep(state)
      newRemoveBugFieldState.splice(action.bugIndex, 1)
      return newRemoveBugFieldState
    case actionTypes.REMOVE_STRING_FIELD:
      let newRemoveStringFieldState = cloneDeep(state)
      newRemoveStringFieldState[action.bugIndex].splice(action.stringIndex, 1)
      return newRemoveStringFieldState
    default:
      return state
  }
}
