import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Heading } from 'grommet'
import CreateBugfixForm from './CreateBugfixForm'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import { withRouter } from 'react-router'

class CreateBugfix extends Component {
  render () {
    const { history } = this.props
    const handleSubmit = async (bugfix) => {
      let project = cloneDeep(bugfix)

      for (let bugIndex = 0; bugIndex < project.bugs.length; bugIndex++) {
        for (let stringIndex = 0; stringIndex < project.bugs[bugIndex].resources.length; stringIndex++) {
          // Convert languages to arrays
          if (!project.bugs[bugIndex].resources[stringIndex].languages) {
            project.bugs[bugIndex].resources[stringIndex].languages = []
          } else {
            let convertedLanguages = []
            for (let [key, value] of Object.entries(project.bugs[bugIndex].resources[stringIndex].languages)) {
              if (value) {
                convertedLanguages.push(key)
              }
            }
            project.bugs[bugIndex].resources[stringIndex].languages = convertedLanguages
          }

          let repoName = project.bugs[bugIndex].resources[stringIndex].component
          let repoSuggestionIndex = this.props.repoSuggestions.indexOf(repoName)
          if (repoSuggestionIndex < 0) {
            console.log('ERROR: -1')
          }

          // Convert resource to ID
          let resourceName = project.bugs[bugIndex].resources[stringIndex].resource
          let resource = filter(this.props.repoStrings[repoName], { name: resourceName })
          project.bugs[bugIndex].resources[stringIndex].resource = resource[0]['_id']
          // Convert repository to ID

          project.bugs[bugIndex].resources[stringIndex].componentId = this.props.repositories[repoSuggestionIndex]['_id']
          delete project.bugs[bugIndex].resources[stringIndex].component

          project.bugs[bugIndex].resources[stringIndex].resourceId = project.bugs[bugIndex].resources[stringIndex].resource
          delete project.bugs[bugIndex].resources[stringIndex].resource

          project.bugs[bugIndex].resources[stringIndex].locales = project.bugs[bugIndex].resources[stringIndex].languages
          delete project.bugs[bugIndex].resources[stringIndex].languages
        }
      }
      await this.props.addBugfixProject(project)
      history.push('/')
    }
    return (
      <Box>
        <Box align='center'>
          <Heading>Create Bugfix Project</Heading>
        </Box>
        <Box border round pad='small'>
          <CreateBugfixForm onSubmit={handleSubmit} />
        </Box>
      </Box>
    )
  }
}

CreateBugfix.propTypes = {
  addBugfixProject: PropTypes.func.isRequired,
  repoStrings: PropTypes.object,
  repositories: PropTypes.arrayOf(PropTypes.object),
  repoSuggestions: PropTypes.array,
  history: PropTypes.object.isRequired
}

export default withRouter(CreateBugfix)
