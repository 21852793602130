import { actionTypes } from '../actions/bugfixList'
import cloneDeep from 'lodash/cloneDeep'

export function bugfixList (state = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_BUGFIX_LIST_SUCCESSFUL:
      return action.payload.map((project) => {
        project.bugs.forEach(bug => (bug.expanded = false))
        if (!project.awsStatus) project.awsStatus = 'No Status'
        return project
      })
    case actionTypes.TOGGLE_BUG:
      const { project, bug } = action
      let newState = cloneDeep(state)
      newState[project].bugs[bug].expanded = !newState[project].bugs[bug].expanded
      return newState
    default:
      return state
  }
}

export function fetchBugfixListHasErrored (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_BUGFIX_LIST_HAS_ERRORED:
    case actionTypes.FETCH_BUGFIX_LIST_HAS_ERRORED_RESET:
      return action.fetchBugfixListHasErrored
    default:
      return state
  }
}

export function fetchBugfixListInProgress (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_BUGFIX_LIST_IN_PROGRESS:
    case actionTypes.FETCH_BUGFIX_LIST_IN_PROGRESS_RESET:
      return action.fetchBugfixListInProgress
    default:
      return state
  }
}
