export default {
  apiGateway: {
    REGION: process.env.REACT_APP_AWS_REGION,
    URL: process.env.REACT_APP_BASE_URL
  },
  cognito: {
  //  REGION
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    //  USER_POOL_ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    //  APP_CLIENT_ID
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    //  IDENTITY_POOL_ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  }
}
