import React, { Component } from 'react'
import { Box, Grommet, ResponsiveContext, Grid } from 'grommet'
import Header from './containers/Header'
import Main from './containers/Main'
import { theme } from './branding'
import { withAuthenticator } from 'aws-amplify-react'
import Amplify from 'aws-amplify'
import config from './auth_config'
import '@aws-amplify/ui/dist/style.css'
import './App.css'

Amplify.configure({
  Auth: config.cognito,
  API: {
    endpoints: [
      {
        name: 'FetchBugfixList',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }, {
        name: 'CreateBugfixList',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }, {
        name: 'FetchRepos',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }, {
        name: 'FetchResources',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }, {
        name: 'GetResource',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

const isProd = process.env.NODE_ENV === 'production'
export class App extends Component {
  render () {
    return (
      <Grommet theme={theme} full>
        <ResponsiveContext.Consumer>
          {size => (
            <Grid
              rows={['xxsmall', 'small']}
              columns={['medium']}
              gap='small'
              areas={[
                { name: 'header', start: [0, 0], end: [1, 0] },
                { name: 'main', start: [0, 1], end: [1, 1] }
              ]}
            >
              <Box gridArea='header'>
                <Header isProd={isProd} />
              </Box>
              <Box gridArea='main' align='center'>
                <Box width='75%'>
                  <Main />
                </Box>
              </Box>
            </Grid>)
          }
        </ResponsiveContext.Consumer>
      </Grommet>
    )
  }
}

export default isProd ? withAuthenticator(App) : App
