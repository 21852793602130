import { actionTypes } from '../actions/repoStrings'
import assign from 'lodash/assign'

export function repoStrings (state = {}, action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSTRINGS_SUCCESSFUL:
      let newState = assign({}, state)
      newState[action.repository] = action.payload
      return newState
    default:
      return state
  }
}

export function fetchRepoStringsHasErrored (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSTRINGS_HAS_ERRORED:
    case actionTypes.FETCH_REPOSTRINGS_HAS_ERRORED_RESET:
      return action.fetchRepoStringsHasErrored
    default:
      return state
  }
}

export function fetchRepoStringsInProgress (state = false, action) {
  switch (action.type) {
    case actionTypes.FETCH_REPOSTRINGS_IN_PROGRESS:
    case actionTypes.FETCH_REPOSTRINGS_IN_PROGRESS_RESET:
      return action.fetchRepoStringsInProgress
    default:
      return state
  }
}
