import React, { Component } from 'react'
import { Box, Button, Heading } from 'grommet'
import { withRouter } from 'react-router'
import { PropTypes } from 'prop-types'
import { Auth } from 'aws-amplify'

export const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
)

export class Header extends Component {
  render () {
    const { history, isProd } = this.props
    return (
      <AppBar>
        <Heading className='header' level='3' margin='none' onClick={() => { history.push('/') }}>LOCMAN BUGFIX</Heading>
        <Button
          label='Create Project'
          onClick={() => { history.push('/bugfix/add') }}
        />
        {isProd && (
          <Button
            label='Sign Out'
            onClick={() => { Auth.signOut() }}
          />
        )}
      </AppBar>
    )
  }
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  isProd: PropTypes.bool
}

export default withRouter(Header)
