import { API } from 'aws-amplify'

export const actionTypes = {
  FETCH_BUGFIX_LIST: 'FETCH_BUGFIX_LIST',
  FETCH_BUGFIX_LIST_HAS_ERRORED: 'FETCH_BUGFIX_LIST_HAS_ERRORED',
  FETCH_BUGFIX_LIST_HAS_ERRORED_RESET: 'FETCH_BUGFIX_LIST_HAS_ERRORED_RESET',
  FETCH_BUGFIX_LIST_IN_PROGRESS: 'FETCH_BUGFIX_LIST_IN_PROGRESS',
  FETCH_BUGFIX_LIST_IN_PROGRESS_RESET: 'FETCH_BUGFIX_LIST_IN_PROGRESS_RESET',
  FETCH_BUGFIX_LIST_SUCCESSFUL: 'FETCH_BUGFIX_LIST_SUCCESSFUL',
  TOGGLE_BUG: 'TOGGLE_BUG'
}

export function fetchBugfixListHasErrored (error) {
  console.log(error)
  return (dispatch) => {
    dispatch(fetchBugfixListInProgressReset())
    dispatch({
      type: actionTypes.FETCH_BUGFIX_LIST_HAS_ERRORED,
      fetchBugfixListHasErrored: true
    })
  }
}

export function fetchBugfixListHasErroredReset () {
  return {
    type: actionTypes.FETCH_BUGFIX_LIST_HAS_ERRORED_RESET,
    fetchBugfixListHasErrored: false
  }
}

export function fetchBugfixListInProgress () {
  return {
    type: actionTypes.FETCH_BUGFIX_LIST_IN_PROGRESS,
    fetchBugfixListInProgress: true
  }
}

export function fetchBugfixListInProgressReset () {
  return {
    type: actionTypes.FETCH_BUGFIX_LIST_IN_PROGRESS_RESET,
    fetchBugfixListInProgress: false
  }
}

export function fetchBugfixListSuccessful (payload) {
  return (dispatch) => {
    dispatch(fetchBugfixListInProgressReset())
    dispatch({
      type: actionTypes.FETCH_BUGFIX_LIST_SUCCESSFUL,
      payload
    })
  }
}

export function fetchBugfixList () {
  return (dispatch) => {
    dispatch(fetchBugfixListHasErroredReset())
    dispatch(fetchBugfixListInProgress())

    return API.get('FetchBugfixList', 'bugfixes')
      .then(
        res => dispatch(fetchBugfixListSuccessful(res))
      )
      .catch(error => dispatch(fetchBugfixListHasErrored(error)))
  }
}

export function toggleBug (project, bug) {
  return {
    type: actionTypes.TOGGLE_BUG,
    project,
    bug
  }
}
