import { API } from 'aws-amplify'

export const actionTypes = {
  ADD_STRING_VALUE: 'ADD_STRING_VALUE',
  REMOVE_BUG_FIELD_STRING_VALUES: 'REMOVE_BUG_FIELD_STRING_VALUES',
  REMOVE_STRING_FIELD_STRING_VALUES: 'REMOVE_STRING_FIELD_STRING_VALUES',
  ADD_BUG_FIELD_STRING_VALUES: 'ADD_BUG_FIELD_STRING_VALUES',
  ADD_STRING_FIELD_STRING_VALUES: 'ADD_STRING_FIELD_STRING_VALUES',
  FETCH_STRING_VALUE: 'FETCH_STRING_VALUE',
  FETCH_STRING_VALUE_HAS_ERRORED: 'FETCH_STRING_VALUE_HAS_ERRORED',
  FETCH_STRING_VALUE_HAS_ERRORED_RESET: 'FETCH_STRING_VALUE_HAS_ERRORED_RESET',
  FETCH_STRING_VALUE_IN_PROGRESS: 'FETCH_STRING_VALUE_IN_PROGRESS',
  FETCH_STRING_VALUE_IN_PROGRESS_RESET: 'FETCH_STRING_VALUE_IN_PROGRESS_RESET',
  FETCH_STRING_VALUE_SUCCESSFUL: 'FETCH_STRING_VALUE_SUCCESSFUL'
}

export function addStringValues (bugIndex, stringIndex, value) {
  return {
    type: actionTypes.ADD_STRING_VALUE,
    bugIndex,
    stringIndex,
    value
  }
}

export function addBugFieldStringValues () {
  return {
    type: actionTypes.ADD_BUG_FIELD_STRING_VALUES
  }
}

export function addStringFieldStringValues (bugIndex) {
  return {
    type: actionTypes.ADD_STRING_FIELD_STRING_VALUES,
    bugIndex
  }
}

export function removeBugFieldStringValues (bugIndex) {
  return {
    type: actionTypes.REMOVE_BUG_FIELD_STRING_VALUES,
    bugIndex
  }
}

export function removeStringFieldStringValues (bugIndex, stringIndex) {
  return {
    type: actionTypes.REMOVE_STRING_FIELD_STRING_VALUES,
    bugIndex,
    stringIndex
  }
}

export function fetchStringValueHasErrored (error) {
  console.log(error)
  return (dispatch) => {
    dispatch(fetchStringValueInProgressReset())
    dispatch({
      type: actionTypes.FETCH_STRING_VALUE_HAS_ERRORED,
      fetchStringValueHasErrored: true
    })
  }
}

export function fetchStringValueHasErroredReset () {
  return {
    type: actionTypes.FETCH_STRING_VALUE_HAS_ERRORED_RESET,
    fetchStringValueHasErrored: false
  }
}

export function fetchStringValueInProgress () {
  return {
    type: actionTypes.FETCH_STRING_VALUE_IN_PROGRESS,
    fetchStringValueInProgress: true
  }
}

export function fetchStringValueInProgressReset () {
  return {
    type: actionTypes.FETCH_STRING_VALUE_IN_PROGRESS_RESET,
    fetchStringValueInProgress: false
  }
}

export function fetchStringValueSuccessful (resource, bugIndex, stringIndex) {
  return (dispatch) => {
    dispatch(fetchStringValueInProgressReset())
    dispatch({
      type: actionTypes.FETCH_STRING_VALUE_SUCCESSFUL,
      resource,
      bugIndex,
      stringIndex
    })
  }
}

export function fetchStringValue (resourceId, bugIndex, stringIndex) {
  if (!resourceId) {
    return dispatch => dispatch(fetchStringValueSuccessful([], bugIndex, stringIndex))
  }
  const sanitizedId = resourceId.replace(/\//g, '%2F')

  return (dispatch) => {
    dispatch(fetchStringValueHasErroredReset())
    dispatch(fetchStringValueInProgress())
    return API.get('GetResource', `resources/${sanitizedId}`)
      .then(res => dispatch(fetchStringValueSuccessful(res, bugIndex, stringIndex)))
      .catch(error => dispatch(fetchStringValueHasErrored(error)))
  }
}
