import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { addBugfixProjectHasErrored, addBugfixProjectInProgess, bugfixProject } from './bugfixProject'
import { fetchRepositoriesHasErrored, fetchRepositoriesInProgress, repositories, repoSuggestions } from './repositories'
import { fetchRepoStringsHasErrored, fetchRepoStringsInProgress, repoStrings } from './repoStrings'
import { repoStringsSuggestions } from './repoStringsSuggestions'
import { stringValues } from './stringValues'
import { fetchBugfixListInProgress, fetchBugfixListHasErrored, bugfixList } from './bugfixList'

export default combineReducers({
  addBugfixProjectHasErrored,
  addBugfixProjectInProgess,
  bugfixProject,
  bugfixList,
  fetchBugfixListHasErrored,
  fetchBugfixListInProgress,
  fetchRepositoriesHasErrored,
  fetchRepositoriesInProgress,
  fetchRepoStringsHasErrored,
  fetchRepoStringsInProgress,
  form: formReducer,
  repositories,
  repoStrings,
  repoStringsSuggestions,
  repoSuggestions,
  stringValues
})
