import { API } from 'aws-amplify'

export const actionTypes = {
  ADD_BUGFIX_PROJECT: 'ADD_BUGFIX_PROJECT',
  ADD_BUGFIX_PROJECT_HAS_ERRORED: 'ADD_BUGFIX_PROJECT_HAS_ERRORED',
  ADD_BUGFIX_PROJECT_HAS_ERRORED_RESET: 'ADD_BUGFIX_PROJECT_HAS_ERRORED_RESET',
  ADD_BUGFIX_PROJECT_IN_PROGRESS: 'ADD_BUGFIX_PROJECT_IN_PROGRESS',
  ADD_BUGFIX_PROJECT_IN_PROGRESS_RESET: 'ADD_BUGFIX_PROJECT_IN_PROGRESS_RESET',
  ADD_BUGFIX_PROJECT_SUCCESSFUL: 'ADD_BUGFIX_PROJECT_SUCCESSFUL'
}

export function addBugfixProjectHasErrored (error) {
  console.log(error)
  return (dispatch) => {
    dispatch(addBugfixProjectInProgressReset())
    dispatch({
      type: actionTypes.ADD_BUGFIX_PROJECT_HAS_ERRORED,
      addBugfixProjectHasErrored: true
    })
  }
}

export function addBugfixProjectHasErroredReset () {
  return {
    type: actionTypes.ADD_BUGFIX_PROJECT_HAS_ERRORED_RESET,
    addBugfixProjectHasErrored: false
  }
}

export function addBugfixProjectInProgress () {
  return {
    type: actionTypes.ADD_BUGFIX_PROJECT_IN_PROGRESS,
    addBugfixProjectInProgress: true
  }
}

export function addBugfixProjectInProgressReset () {
  return {
    type: actionTypes.ADD_BUGFIX_PROJECT_IN_PROGRESS_RESET,
    addBugfixProjectInProgress: false
  }
}

export function addBugfixProjectSuccessful (payload) {
  return (dispatch) => {
    dispatch(addBugfixProjectInProgressReset())
    dispatch({
      type: actionTypes.ADD_BUGFIX_PROJECT_SUCCESSFUL,
      payload
    })
  }
}

export function addBugfixProject (bugfix) {
  return (dispatch) => {
    dispatch(addBugfixProjectHasErroredReset())
    dispatch(addBugfixProjectInProgress())

    return API.post('CreateBugfixList', 'bugfixes', { body: bugfix })
      .then(res => dispatch(addBugfixProjectSuccessful(res)))
      .catch(error => dispatch(addBugfixProjectHasErrored(error)))
  }
}
