import { API } from 'aws-amplify'

export const actionTypes = {
  FETCH_REPOSTRINGS: 'FETCH_REPOSTRINGS',
  FETCH_REPOSTRINGS_HAS_ERRORED: 'FETCH_REPOSTRINGS_HAS_ERRORED',
  FETCH_REPOSTRINGS_HAS_ERRORED_RESET: 'FETCH_REPOSTRINGS_HAS_ERRORED_RESET',
  FETCH_REPOSTRINGS_IN_PROGRESS: 'FETCH_REPOSTRINGS_IN_PROGRESS',
  FETCH_REPOSTRINGS_IN_PROGRESS_RESET: 'FETCH_REPOSTRINGS_IN_PROGRESS_RESET',
  FETCH_REPOSTRINGS_SUCCESSFUL: 'FETCH_REPOSTRINGS_SUCCESSFUL'
}

export function fetchRepoStringsHasErrored (error) {
  console.log(error)
  return (dispatch) => {
    dispatch(fetchRepoStringsInProgressReset())
    dispatch({
      type: actionTypes.FETCH_REPOSTRINGS_HAS_ERRORED,
      fetchRepoStringsHasErrored: true
    })
  }
}

export function fetchRepoStringsHasErroredReset () {
  return {
    type: actionTypes.FETCH_REPOSTRINGS_HAS_ERRORED_RESET,
    fetchRepoStringsHasErrored: false
  }
}

export function fetchRepoStringsInProgress () {
  return {
    type: actionTypes.FETCH_REPOSTRINGS_IN_PROGRESS,
    fetchRepoStringsInProgress: true
  }
}

export function fetchRepoStringsInProgressReset () {
  return {
    type: actionTypes.FETCH_REPOSTRINGS_IN_PROGRESS_RESET,
    fetchRepoStringsInProgress: false
  }
}

export function fetchRepoStringsSuccessful (payload, repository, bugIndex, stringIndex) {
  return (dispatch) => {
    dispatch(fetchRepoStringsInProgressReset())
    dispatch({
      type: actionTypes.FETCH_REPOSTRINGS_SUCCESSFUL,
      repository,
      payload,
      bugIndex,
      stringIndex
    })
  }
}

export function fetchRepoStrings (repository, bugIndex, stringIndex) {
  return (dispatch) => {
    dispatch(fetchRepoStringsHasErroredReset())
    dispatch(fetchRepoStringsInProgress())
    return API.get('FetchResources', `repositories/${repository.id}/resources`)
      .then(res => dispatch(fetchRepoStringsSuccessful(res, repository.name, bugIndex, stringIndex)))
      .catch(error => dispatch(fetchRepoStringsHasErrored(error)))
  }
}
