import { actionTypes } from '../actions/stringValues'

import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'

export function stringValues (state = [], action) {
  switch (action.type) {
    case actionTypes.FETCH_STRING_VALUE_SUCCESSFUL:
      let newState = cloneDeep(state)
      newState[action.bugIndex][action.stringIndex] = action.resource
      newState[action.bugIndex][action.stringIndex].translations = sortBy(newState[action.bugIndex][action.stringIndex].translations, (translation) => {
        return translation.locale
      })
      return newState
    case actionTypes.ADD_BUG_FIELD_STRING_VALUES:
      let newBugFieldState = cloneDeep(state)
      newBugFieldState.push([])
      return newBugFieldState
    case actionTypes.ADD_STRING_FIELD_STRING_VALUES:
      let newStringFieldState = cloneDeep(state)
      newStringFieldState[action.bugIndex].push([])
      return newStringFieldState
    case actionTypes.REMOVE_BUG_FIELD_STRING_VALUES:
      let newRemoveBugFieldState = cloneDeep(state)
      newRemoveBugFieldState.splice(action.bugIndex, 1)
      return newRemoveBugFieldState
    case actionTypes.REMOVE_STRING_FIELD_STRING_VALUES:
      let newRemoveStringFieldState = cloneDeep(state)
      newRemoveStringFieldState[action.bugIndex].splice(action.stringIndex, 1)
      return newRemoveStringFieldState
    default:
      return state
  }
}
