import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Box, FormField, TextInput } from 'grommet'

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // parent, the component name
      parent: '',
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  setParent = userInput => {
    const { index, parents, setParent } = this.props;
    const p = [...parents];
    p[index] = userInput;
    setParent(p);
  }

  setStringId = userInput => {
    const { index, stringIds, setStringId } = this.props;
    const ids = [...stringIds];
    ids[index] = userInput;
    setStringId(ids);
  }

  displayStrings (userInput) {
    let component, string;
    if (this.props.stringId) {
      component = userInput;
      string = this.props.stringId;
    }
    if (this.props.parent) {
      component = this.props.parent;
      string = userInput;
    }
    const { index, bugIndex } = this.props;
    const { fetchStringValue, repoStrings } = this.props.resourceSuccessProps;
    fetchStringValue(undefined, bugIndex, index);
    if (component && string) {
      const resource = repoStrings[component] && repoStrings[component].find(resource => resource.name === string);
      fetchStringValue(resource && resource._id, bugIndex, index);
    }
  }

  getTranslations (userInput) {
    this.displayStrings(userInput);
    const component = this.props.parent || userInput;
    const componentSuggestionIndex = this.props.suggestions.indexOf(component);
    if (componentSuggestionIndex >= 0) {
      const { fetchRepoStrings, repoStrings, repositories, addSuggestions, bugIndex, stringIndex } = this.props.onSuccessProps;
      if (!(component in repoStrings)) {
        const componentId = repositories[componentSuggestionIndex] && repositories[componentSuggestionIndex]['_id'];
        if (componentId) {
          fetchRepoStrings({ id: componentId, name: component }, bugIndex, stringIndex)
            .then(() => this.displayStrings(userInput));
        }
      } else {
        const suggestions = repoStrings[component].map(({ name }) => !!name && name);
        addSuggestions(bugIndex, stringIndex, suggestions);
        this.displayStrings(userInput);
      }
    }
  }

  hookStates(userInput) {
    const { parents, parent, setStringId } = this.props;
    // is Component
    if (parents) this.setParent(userInput);
    // is String ID
    if (parent || setStringId) {
      this.setState({ parent: parent || '' });
      this.setStringId(userInput);
    }
  }

  onChange = e => {
    e.persist();
    const { suggestions, changeFieldValue, input } = this.props;
    const userInput = e.currentTarget.value;
    if (userInput.length > 1) {
      // Filter our suggestions that don't contain the user's input
      let filteredSuggestions = suggestions.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      if (filteredSuggestions.length > 10) {
        filteredSuggestions = filteredSuggestions.slice(0, 10)
      }

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true
      })
    }

    this.setState({ userInput });
    changeFieldValue(input.name, userInput);

    this.hookStates(userInput);
    this.getTranslations(userInput);
  };

  onClick = e => {
    e.persist();
    const { changeFieldValue, input } = this.props;
    let userInput = e.currentTarget.innerText;
    let tabHint = userInput.indexOf('\nTAB \u2b7e')
    if (tabHint > 0) userInput = userInput.substring(0, tabHint);
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: userInput
    });
    
    changeFieldValue(input.name, userInput);
    this.hookStates(userInput);
    this.getTranslations(userInput);
  };

  onKeyDown = e => {
    e.persist()
    const { activeSuggestion, filteredSuggestions } = this.state;
    const { changeFieldValue, input } = this.props;

    // User pressed the tab key
    if (e.keyCode === 9 && filteredSuggestions.length) {
      const userInput = !!e.currentTarget.value ? filteredSuggestions[activeSuggestion] : '';

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput
      });

      changeFieldValue(input.name, userInput);
      this.hookStates(userInput);
      this.getTranslations(userInput);
    } 
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  onMouseEnter = e => {
    const { filteredSuggestions } = this.state;

    let hoverSelect = filteredSuggestions.indexOf(e.currentTarget.innerText)

    this.setState({ activeSuggestion: hoverSelect })
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onMouseEnter,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      let suggestions = [...filteredSuggestions];
      if (this.props.parent) {
        suggestions = suggestions.concat(
          this.props.suggestions.filter(suggestion =>
              !suggestions.includes(suggestion) &&
              suggestion.toLowerCase().indexOf(this.state.userInput.toLowerCase()) > -1
        ));
      }
      if (suggestions.length) {
        if (suggestions.length === 1 && this.state.userInput === suggestions[0]) {
          suggestionsListComponent = null;
        } else {
          suggestionsListComponent = (
            <Box background='light-3'>
              {suggestions.map((suggestion, index) => {
                let background='light-3'
                let active = false
                let tab = 'TAB \u2b7e'
                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  background = 'brand'
                  active = true
                }
  
                return (
                  <Box background={background} key={suggestion} onClick={onClick} onMouseEnter={onMouseEnter}>
                    <Box justify='between' direction='row' pad='xxsmall'>
                      <Box margin={active ? 'xsmall' : ''}>{suggestion}</Box>
                      { active && <Box margin='xsmall'>{tab}</Box>}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          );
        }
      } else {
        suggestionsListComponent = (
          <Box background='status-warning' >
            <Box pad='xsmall'>Not valid!</Box>
          </Box>
        );
      }
    }

    return (
      <Fragment>
        <FormField
          label={this.props.label}
          htmlFor={this.props.label}
          help={this.props.help}
          error={this.props.meta.touched && this.props.meta.error}
          {...this.props.input}
        >
          <TextInput
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            placeholder={'Enter ' + this.props.label}
          />
        </FormField>
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
