import React, { useState } from 'react'
import { connect } from 'react-redux'
import { change, Field, FieldArray, reduxForm } from 'redux-form'
// import validate from './validate'
import { Box, Button, FormField, Text, TextInput, CheckBox, Heading, Table, TableHeader, TableRow, TableCell, TableBody } from 'grommet'
import { FormAdd, FormClose, FormUpload, Task } from 'grommet-icons'
import Autocomplete from '../Autocomplete'
import { fetchRepositories } from '../../actions/repositories'
import { fetchRepoStrings } from '../../actions/repoStrings'
import { addBugFieldStringValues, addStringFieldStringValues, removeBugFieldStringValues, removeStringFieldStringValues, fetchStringValue } from '../../actions/stringValues'
import { addBugField, addStringField, addSuggestions, removeBugField, removeStringField } from '../../actions/repoStringsSuggestions'

const allLang = ['de-DE', 'es-ES', 'fr-FR', 'it-IT', 'ja-JP', 'ko-KR', 'nl-NL', 'pl-PL', 'pt-BR', 'ru-RU', 'sv-SE', 'tr-TR', 'zh-CN', 'zh-TW']

// Validation
const required = value => value ? undefined : 'Required'
const jiraMatcher = /\d+-[A-Z]+(?!-?[a-zA-Z]{1,10})/g
const jiraIdFormat = value => value.split('').reverse().join('').match(jiraMatcher) ? undefined : 'Incorrect format'
// const validate = values => {
//   const errors = {}

//   if (!values.bugs || !values.bugs.length) {
//     errors.bugs = { _error: 'At leasts one bug must be entered' }
//   } else {
//     const bugsArrayErrors = []
//     values.bugs.forEach((bug, bugIndex) => {
//       const bugErrors = {}
//       if (!bug.resources || !bug.resources.length) {
//         bugErrors.resources = { _error: 'At least one string must be entered' }
//       } else {
//         bug.resources.forEach((bugString, stringIndex) => {
//           const someLanguages = some(bugString.languages, (included) => {
//             return included
//           })
//           if (!someLanguages) {
//             errors.bugs[bugIndex].resources[stringIndex].languages = 'At least one language is required'
//           }
//         })
//       }

//     })
//   }

//   return errors
// }

// Field Rendering

const renderField = ({ input, id, label, help, meta: { touched, error }, ...custom }) => (
  <FormField
    label={label}
    htmlFor={label}
    help={help}
    error={touched && error}
    {...input}
    {...custom} >
    <TextInput placeholder={'Enter ' + label} />
  </FormField>
)

const renderAutoSuggestField = ({ index, input, id, label, help, meta, parents, parent, setParent, changeFieldValue, suggestions, onSuccess, onSuccessProps, ...custom }) => (
  <Autocomplete
    index={index}
    placeholder={'Enter ' + label}
    suggestions={suggestions}
    input={input}
    id={id}
    label={label}
    help={help}
    meta={meta}
    parent={parent}
    parents={parents}
    setParent={setParent}
    changeFieldValue={changeFieldValue}
    onSuccess={onSuccess}
    onSuccessProps={onSuccessProps}
    {...input}
    {...custom}
  />
)

const renderCheckbox = ({ input, id, label, help, meta: { touched, error }, ...custom }) => (
  <CheckBox
    checked={!!input.value}
    onChange={input.onChange}
  />
)

const renderTranslatedCheckbox = ({ input, id, label, help, meta: { touched, error }, ...custom }) => (
  <Box direction='row' pad='small'>
    <Text>{label}</Text>
    <Box pad={{ left: 'small' }}>
      <CheckBox
        checked={input.value ? true : false}
        onChange={input.onChange}
      />
    </Box>
  </Box>
)

const renderBugs = ({
  fields,
  changeFieldValue,
  repoSuggestions,
  fetchRepoStrings,
  fetchStringValue,
  repoSuccessProps,
  resourceSuccessProps,
  repoStrings,
  repoStringsSuggestions,
  addBugField,
  addBugFieldStringValues,
  addStringField,
  addStringFieldStringValues,
  addSuggestions,
  removeBugField,
  removeBugFieldStringValues,
  removeStringField,
  removeStringFieldStringValues,
  stringValues
}) => {
  const [parents, setParent] = useState([]);
  const [stringIds, setStringId] = useState([]);
  return (<Box border round pad='small'>
    <ul>
      {fields.map((bug, index) =>
        <li key={index}>
          <Box align='end' justify='end'>
            <Button
              icon={<FormClose color='status-critical' />}
              onClick={() => { removeBug(fields, removeBugField, removeBugFieldStringValues, index) }}
            />
          </Box>
          <Field
            name={`${bug}.jiraId`}
            type='text'
            component={renderField}
            label='Jira ID'
            validate={[ required, jiraIdFormat ]}
          />
          <FieldArray
            name={`${bug}.resources`}
            component={renderStrings}
            parents={parents}
            setParent={setParent}
            stringIds={stringIds}
            setStringId={setStringId}
            changeFieldValue={changeFieldValue}
            repoSuggestions={repoSuggestions}
            fetchRepoStrings={fetchRepoStrings}
            repoSuccessProps={repoSuccessProps}
            resourceSuccessProps={resourceSuccessProps}
            repoStrings={repoStrings}
            repoStringsSuggestions={repoStringsSuggestions}
            bugIndex={index}
            addStringField={addStringField}
            addStringFieldStringValues={addStringFieldStringValues}
            addSuggestions={addSuggestions}
            removeStringField={removeStringField}
            removeStringFieldStringValues={removeStringFieldStringValues}
            stringValues={stringValues}
          />
        </li>
      )}
      <li>
        <Button
          icon={<FormAdd />}
          label='Add Bug'
          onClick={() => addBug(fields, addBugField, addBugFieldStringValues)}
          margin={{ top: 'xsmall' }}
        />
      </li>
    </ul>
  </Box>
  )
}

const renderStrings = ({
  fields,
  parents,
  setParent,
  stringIds,
  setStringId,
  changeFieldValue,
  repoSuggestions,
  fetchRepoStrings,
  fetchStringValue,
  repoSuccessProps,
  resourceSuccessProps,
  repoStrings,
  repoStringsSuggestions,
  bugIndex,
  addStringField,
  addStringFieldStringValues,
  addSuggestions,
  removeStringField,
  removeStringFieldStringValues,
  stringValues
}) => (
  <Box border round pad='small'>
    <ul>
      {fields.map((bugString, index) =>
        <li key={index}>
          <Box align='end' justify='end'>
            <Button
              icon={<FormClose color='status-critical' />}
              onClick={() => removeString(fields, removeStringField, removeStringFieldStringValues, bugIndex, index)}
            />
          </Box>
          <Field
            index={index}
            bugIndex={bugIndex}
            name={`${bugString}.component`}
            type='text'
            component={renderAutoSuggestField}
            label='Component'
            validate={[ required ]}
            parents={parents}
            stringId={stringIds[index]}
            setParent={setParent}
            changeFieldValue={changeFieldValue}
            suggestions={repoSuggestions}
            onSuccessProps={Object.assign(repoSuccessProps, { bugIndex, stringIndex: index })}
            resourceSuccessProps={resourceSuccessProps}
          />
          <Field
            index={index}
            bugIndex={bugIndex}
            parent={parents[index]}
            stringIds={stringIds}
            setStringId={setStringId}
            name={`${bugString}.resource`}
            type='text'
            component={renderAutoSuggestField}
            label='String ID'
            validate={[ required ]}
            changeFieldValue={changeFieldValue}
            suggestions={repoStringsSuggestions[bugIndex][index]}
            resourceSuccessProps={resourceSuccessProps}
            onSuccessProps={Object.assign(repoSuccessProps, { bugIndex, stringIndex: index })}
          />
          <Box>
            {renderTranslations({ stringValues: stringValues[bugIndex][index] })}
          </Box>
          {renderLanguages({ languages: allLang, bugString: bugString, fields: fields, index: index, changeFieldValue: changeFieldValue })}
          <Field
            name={`${bugString}.description`}
            type='text'
            component={renderField}
            label='Problem Description' />
          <Field
            name={`${bugString}.translationProvided`}
            component={renderTranslatedCheckbox}
            type='checkbox'
            label='Translation Provided?'
          />
        </li>
      )}
      <li>
        <Box align='center'>
          <Button
            icon={<FormAdd />}
            label='Add String'
            onClick={() => addString(fields, addStringField, addStringFieldStringValues, bugIndex)}
          />
        </Box>
      </li>
    </ul>
  </Box>
)

const renderLanguages = ({ languages, bugString, fields, index, changeFieldValue }) => (
  <Box>
    <Box direction='row' justify='between' pad='small'>
      <Text>Select Languages</Text>
      <Button
        icon={<Task />}
        label='Select All'
        onClick={() => selectAllLangs(allLang, fields, index, bugString, changeFieldValue)}
      />
    </Box>
    <Box direction='row' justify='between'>
      {renderCheckboxes({ languages: languages, bugString: bugString })}
    </Box>
  </Box>
)

const renderCheckboxes = ({ languages, bugString }) => (
  languages.map((language, index) => (
    <Box key={index} align='center'>
      {language}
      <Field name={`${bugString}.languages.${language}`} id={language} component={renderCheckbox} type='checkbox' />
    </Box>
  ))
)

const renderTranslations = ({ stringValues }) => {
  if (stringValues.constructor === Array) {
    return null
  }
  const columns = [
    {
      property: 'locale',
      label: 'Locale',
      dataScope: 'row',
      format: translations => <strong>{translations.locale}</strong>
    },
    {
      property: 'value',
      label: 'Translation'
    }
  ]
  return (
    <Box round background='light-2'>
      <Box pad='small' direction='row' gap='small'>
        <Heading level='4' color='brand'>Source String</Heading>
        <Heading level='4'>{stringValues.value}</Heading>
      </Box>
      <Table>
        <TableHeader>
          <TableRow>
            {columns.map(c => (
              <TableCell key={c.property} scope='col' border='bottom'>
                <Text>{c.label}</Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {stringValues.translations.map(datum => (
            <TableRow key={datum._id}>
              {columns.map(c => (
                <TableCell key={c.property} scope={c.dataScope}>
                  <Text>
                    {c.format ? c.format(datum) : datum[c.property]}
                  </Text>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

// Helpers

function selectAllLangs (languages, bugStringFields, indexOfBugString, bugString, changeFieldValue) {
  let currentField = bugStringFields.get(indexOfBugString)
  let newLanguages = {}
  currentField.languages = languages.forEach(language => {
    newLanguages[language] = true
  })
  currentField.languages = newLanguages
  changeFieldValue(`${bugString}.languages`, newLanguages)
}

function addBug (fields, addBugField, addBugFieldStringValues) {
  fields.push({})
  addBugField()
  addBugFieldStringValues()
}

function removeBug (fields, removeBugField, removeBugFieldStringValues, bugIndex) {
  fields.remove(bugIndex)
  removeBugField(bugIndex)
  removeBugFieldStringValues(bugIndex)
}

function addString (fields, addStringField, addStringFieldStringValues, bugIndex) {
  fields.push({})
  addStringField(bugIndex)
  addStringFieldStringValues(bugIndex)
}

function removeString (fields, removeStringField, removeStringFieldStringValues, bugIndex, stringIndex) {
  fields.remove(stringIndex)
  removeStringField(bugIndex, stringIndex)
  removeStringFieldStringValues(bugIndex, stringIndex)
}

export const CreateBugfixFormDetached = (props) => {
  const {
    handleSubmit,
    repositories,
    fetchRepoStrings,
    fetchStringValue,
    repoStrings,
    changeFieldValue,
    repoSuggestions,
    repoStringsSuggestions,
    addBugField,
    addBugFieldStringValues,
    addStringField,
    addStringFieldStringValues,
    addSuggestions,
    removeBugField,
    removeBugFieldStringValues,
    removeStringField,
    removeStringFieldStringValues,
    stringValues
  } = props
  const repoSuccessProps = {
    fetchRepoStrings,
    repoStrings,
    repoSuggestions,
    repositories,
    addSuggestions
  }
  const resourceSuccessProps = {
    fetchStringValue,
    repoStrings
  }
  return (
    <form onSubmit={handleSubmit} autoComplete='off' autoCorrect='off' autoCapitalize='off'>
      <Field
        name='name'
        type='text'
        component={renderField}
        label='Project Name'
        validate={[ required ]}
      />
      <FieldArray
        name='bugs'
        component={renderBugs}
        changeFieldValue={changeFieldValue}
        repoSuggestions={repoSuggestions}
        repoSuccessProps={repoSuccessProps}
        resourceSuccessProps={resourceSuccessProps}
        repoStrings={repoStrings}
        repoStringsSuggestions={repoStringsSuggestions}
        addBugField={addBugField}
        addBugFieldStringValues={addBugFieldStringValues}
        addStringField={addStringField}
        addStringFieldStringValues={addStringFieldStringValues}
        addSuggestions={addSuggestions}
        removeBugField={removeBugField}
        removeBugFieldStringValues={removeBugFieldStringValues}
        removeStringField={removeStringField}
        removeStringFieldStringValues={removeStringFieldStringValues}
        stringValues={stringValues}
      />
      <Box align='center' pad='small'>
        <Button
          type='submit'
          icon={<FormUpload />}
          label='Submit'
        />
      </Box>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    repositories: state.repositories,
    repoStrings: state.repoStrings,
    repoStringsSuggestions: state.repoStringsSuggestions,
    repoSuggestions: state.repoSuggestions,
    stringValues: state.stringValues
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRepositories: () => dispatch(fetchRepositories()),
    fetchRepoStrings: (repository, bugIndex, stringIndex) => dispatch(fetchRepoStrings(repository, bugIndex, stringIndex)),
    fetchStringValue: (resourceId, bugIndex, stringIndex) => dispatch(fetchStringValue(resourceId, bugIndex, stringIndex)),
    changeFieldValue: (field, value) => {
      dispatch(change('createBugfixForm', field, value))
    },
    addBugField: () => dispatch(addBugField()),
    addBugFieldStringValues: () => dispatch(addBugFieldStringValues()),
    addStringField: (bugIndex) => dispatch(addStringField(bugIndex)),
    addStringFieldStringValues: (bugIndex) => dispatch(addStringFieldStringValues(bugIndex)),
    addSuggestions: (bugIndex, stringIndex, suggestions) => dispatch(addSuggestions(bugIndex, stringIndex, suggestions)),
    removeBugField: (bugIndex) => dispatch(removeBugField(bugIndex)),
    removeBugFieldStringValues: (bugIndex) => dispatch(removeBugFieldStringValues(bugIndex)),
    removeStringField: (bugIndex, stringIndex) => dispatch(removeStringField(bugIndex, stringIndex)),
    removeStringFieldStringValues: (bugIndex, stringIndex) => dispatch(removeStringFieldStringValues(bugIndex, stringIndex))
  }
}

const decoratedForm = connect(mapStateToProps, mapDispatchToProps)(CreateBugfixFormDetached)

let createBugfixForm = reduxForm({
  form: 'createBugfixForm'
})(decoratedForm)

export default createBugfixForm
