import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box } from 'grommet'
import CreateBugfix from '../../components/CreateBugfix'
import { addBugfixProject } from '../../actions/bugfixProject'
import { fetchRepositories } from '../../actions/repositories'

export class AddBugfixProject extends Component {
  async componentDidMount () {
    await this.props.fetchRepositories()
  }

  render () {
    return (
      <Box margin='medium'>
        <CreateBugfix
          addBugfixProject={this.props.addBugfixProject}
          repositories={this.props.repositories}
          repoStrings={this.props.repoStrings}
          repoSuggestions={this.props.repoSuggestions}
        />
      </Box>
    )
  }
}

AddBugfixProject.propTypes = {
  addBugfixProject: PropTypes.func.isRequired,
  fetchRepositories: PropTypes.func.isRequired,
  repositories: PropTypes.array.isRequired,
  repoStrings: PropTypes.object.isRequired,
  repoSuggestions: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    repositories: state.repositories,
    repoStrings: state.repoStrings,
    repoSuggestions: state.repoSuggestions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addBugfixProject: (bugfix) => dispatch(addBugfixProject(bugfix)),
    fetchRepositories: () => dispatch(fetchRepositories())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBugfixProject)
